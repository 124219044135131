import * as React from "react"
import { Link } from "gatsby"
import "./css/socicon.css"

const Footer = () => {
  return (
    <footer className="py-8 py-md-11 bg-gray-200">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-4 col-lg-3">
            <span className="brand footer-brand img-fluid mb-2">
              <i className="fa fa-calendar" aria-hidden="true"></i> harmonizely{" "}
            </span>

            <p className="text-gray-700 mb-3">
              A better way to schedule meetings.
            </p>

            <ul className="list-inline list-social mb-4">
              <li className="list-inline-item list-social-item mr-3">
                <a
                  rel="nofollow noopener noreferrer"
                  href="https://twitter.com/harmonizely"
                  aria-label="Twitter"
                  target="_blank"
                >
                  <i className="socicon socicon-twitter icon icon--xs"></i>
                </a>
              </li>
              <li className="list-inline-item list-social-item mr-3">
                <a
                  rel="nofollow noopener noreferrer"
                  href="https://facebook.com/harmonizely/"
                  aria-label="Facebook"
                  target="_blank"
                >
                  <i className="socicon socicon-facebook icon icon--xs"></i>
                </a>
              </li>
              <li className="list-inline-item list-social-item mr-3">
                <a
                  rel="nofollow noopener noreferrer"
                  href="https://instagram.com/harmonizely"
                  aria-label="Instagram"
                  target="_blank"
                >
                  <i className="ocicon socicon-instagram icon icon--xs"></i>
                </a>
              </li>
              <li className="list-inline-item list-social-item">
                <a
                  rel="nofollow noopener noreferrer"
                  href="https://www.linkedin.com/company/harmonizely"
                  aria-label="LinkedIn"
                  target="_blank"
                >
                  <i className="socicon socicon-linkedin icon icon--xs"></i>
                </a>
              </li>
            </ul>

            <p className="mb-2">Made with ❤️ in Poland.</p>
          </div>
          <div className="col-6 col-md-4 col-lg-2">
            <h1 className="h6 font-weight-bold text-uppercase text-gray-700">
              Explore
            </h1>

            <ul className="list-unstyled list-footer text-muted mb-6 mb-md-8 mb-lg-0">
              <li className="mb-3">
                <Link className="text-reset" to="/">
                  Home
                </Link>
              </li>
              <li className="mb-3">
                <Link className="text-reset" to="/why-us/">
                  Why Harmonizely
                </Link>
              </li>
              <li className="mb-3">
                <Link className="text-reset" to="/about/">
                  About
                </Link>
              </li>
              <li className="mb-3">
                <Link className="text-reset" to="/for-teams/">
                For Teams
                </Link>
              </li>
              <li className="mb-3">
                <Link className="text-reset" to="/pricing/">
                  Pricing
                </Link>
              </li>
              <li className="mb-3">
                <Link className="text-reset" to="/features/">
                  Features
                </Link>
              </li>
              <li className="mb-3">
                <a
                  rel="nofollow noopener noreferrer"
                  target="_blank"
                  href="https://help.harmonizely.com/en/collections/2072301-faqs-and-troubleshooting"
                  className="text-reset"
                >
                  FAQ
                </a>
              </li>
              <li className="mb-3">
                <a
                  rel="nofollow noopener noreferrer"
                  target="_blank"
                  href="https://help.harmonizely.com/"
                  className="text-reset"
                >
                  Knowledge Base
                </a>
              </li>
              <li>
                <a
                  rel="nofollow noopener noreferrer"
                  target="_blank"
                  href="https://blog.harmonizely.com/"
                  className="text-reset"
                >
                  Blog
                </a>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md-4 col-lg-2">

          <h1 className="h6 font-weight-bold text-uppercase text-gray-700">
              Solutions
            </h1>

            <ul className="list-unstyled list-footer text-muted mb-6 mb-md-8 mb-lg-0">
              <li className="mb-3">
                <Link className="text-reset" to="/solutions/education/">
                  Education
                </Link>
              </li>
            </ul>
            <ul className="list-unstyled list-footer text-muted mb-6 mb-md-8 mb-lg-0">
              <li className="mb-3">
                <Link className="text-reset" to="/solutions/sales/">
                  Sales
                </Link>
              </li>
            </ul>
            <ul className="list-unstyled list-footer text-muted mb-6 mb-md-8 mb-lg-0">
              <li className="mb-3">
                <Link className="text-reset" to="/solutions/marketing/">
                  Marketing
                </Link>
              </li>
            </ul>
            <ul className="list-unstyled list-footer text-muted mb-6 mb-md-8 mb-lg-0">
              <li className="mb-3">
                <Link className="text-reset" to="/solutions/recruitment/">
                  Recruitment
                </Link>
              </li>
            </ul>
            <ul className="list-unstyled list-footer text-muted mb-6 mb-md-8 mb-lg-0">
              <li className="mb-5">
                <Link className="text-reset" to="/solutions/freelancers/">
                  Freelancers
                </Link>
              </li>
            </ul>
           
            <h1 className="h6 font-weight-bold text-uppercase text-gray-700">
              Customers
            </h1>

            <ul className="list-unstyled list-footer text-muted mb-0">
              <li className="mb-3">
                <a
                  rel="nofollow noopener noreferrer"
                  target="_blank"
                  href="https://www.capterra.com/p/182405/Harmonizely/"
                  className="text-reset"
                >
                  Capterra
                </a>
              </li>
              <li className="mb-3">
                <a
                  rel="nofollow noopener noreferrer"
                  target="_blank"
                  href="https://www.g2.com/products/harmonizely"
                  className="text-reset"
                >
                  G2 Crowd
                </a>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md-4 offset-md-4 col-lg-2 offset-lg-0">
            
            <h1 className="h6 font-weight-bold text-uppercase text-gray-700">
              Comparisons
            </h1>

            <ul className="list-unstyled list-footer text-muted mb-6 mb-md-8 mb-lg-0">
              <li className="mb-3">
                <Link className="text-reset" to="/calendly-alternative/">
                  Calendly
                </Link>
              </li>
              <li className="mb-3">
                <Link className="text-reset" to="/acuity-alternative/">
                  Acuity Scheduling
                </Link>
              </li>
              <li className="mb-3">
                <Link className="text-reset" to="/doodle-alternative/">
                  Doodle
                </Link>
              </li>
              <li className="mb-3">
                <Link className="text-reset" to="/youcanbookme-alternative/">
                  YouCanBook.me
                </Link>
              </li>
              <li className="mb-3">
                <Link className="text-reset" to="/xai-alternative/">
                  X.ai
                </Link>
              </li>
              <li className="mb-3">
                <Link className="text-reset" to="/front-alternative/">
                  Front
                </Link>
              </li>
            </ul>
           
          </div>
          <div className="col-6 col-md-4 col-lg-2">
          <h1 className="h6 font-weight-bold text-uppercase text-gray-700">
              Resources
            </h1>

            <ul className="list-unstyled list-footer text-muted mb-0">
              <li className="mb-3">
                <a
                  rel="nofollow noopener noreferrer"
                  target="_blank"
                  href="https://developers.harmonizely.com"
                  className="text-reset"
                >
                  Developers
                </a>
              </li>
              <li className="mb-3">
                <a
                  rel="nofollow noopener noreferrer"
                  target="_blank"
                  href="https://harmonizely.firstpromoter.com"
                  className="text-reset"
                >
                  Affiliate Program
                </a>
              </li>
              <li className="mb-3">
                <a
                  rel="nofollow noopener noreferrer"
                  target="_blank"
                  href="https://trello.com/b/7rpC1jKm/harmonizely-public-roadmap"
                  className="text-reset"
                >
                  Roadmap
                </a>
              </li>
              <li className="mb-5">
                <a
                  rel="nofollow noopener noreferrer"
                  target="_blank"
                  href="https://status.harmonizely.com"
                  className="text-reset"
                >
                  Status
                </a>
              </li>
            </ul>
            <h1 className="h6 font-weight-bold text-uppercase text-gray-700">
              Legal
            </h1>

            <ul className="list-unstyled list-footer text-muted mb-0">
              <li className="mb-3">
                <Link className="text-reset" to="/terms/">
                  Terms
                </Link>
              </li>
              <li className="mb-3">
                <Link className="text-reset" to="/privacy/">
                  Privacy Policy
                </Link>
              </li>
              <li className="mb-3">
                <Link className="text-reset" to="/security/">
                  Security
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
