import * as React from "react"
import { Link } from 'gatsby';
import Footer from './footer';
import CookieConsent from "react-cookie-consent";
import {Helmet} from 'react-helmet'

import 'bootstrap/dist/css/bootstrap.min.css';
import './css/stack-interface.css';
import './css/theme.css';
import './css/custom.css';

interface Props {
    children?: any
}

const Layout = ({ children }: Props) => {
    return (
        <>
        <Helmet>
        <script type="text/javascript">{`!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});`}</script>
        <script type="text/javascript">{`window.Beacon('init', '871e31e6-ac40-4f29-a0f3-36e424807296');`}</script>
        <script type="text/javascript">{`  var _paq = window._paq = window._paq || [];  _paq.push(['trackPageView']);  _paq.push(['enableLinkTracking']);  (function() {    var u="https://counter.simplybook.me/";    _paq.push(['setTrackerUrl', u+'matomo.php']);    _paq.push(['setSiteId', '30']);    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];    g.type='text/javascript'; g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);  })();`}</script>
        </Helmet>
        <div className="nav-container">
            <div className="bar bar--sm visible-xs">
                <div className="container">
                    <div className="row">
                        <div className="col-7 col-md-2">
                            <Link className="brand-link" to="/"><span className="brand logo"><i
                                className="fa fa-calendar" aria-hidden="true"></i> harmonizely</span></Link>
                        </div>
                        <div className="col-5 text-right">
                            <div className="dropdown">
                                <div className="hamburger-toggle"
                                    id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    <i className="icon icon--sm stack-interface stack-menu"></i>
                                </div>
                                <div className="dropdown-menu dropdown-menu-right"
                                    aria-labelledby="dropdownMenuButton">
                                    <Link className="dropdown-item" to="/why-us/">Why Harmonizely?</Link>
                                    <Link className="dropdown-item" to="/features">Features</Link>
                                    <Link className="dropdown-item" to="/for-teams/">For Teams</Link>
                                    <Link className="dropdown-item" to="/pricing">Pricing</Link>
                                    <Link className="dropdown-item" to="/about">About</Link>
                                    <a className="dropdown-item" href={process.env.BLOG_URL}>Blog</a>
                                    <a className="dropdown-item" href={process.env.LOGIN_URL}>
                                        <strong>Log in</strong>
                                    </a>
                                    <a className="dropdown-item" href={process.env.REGISTER_URL}>
                                        <strong>Sign up free</strong>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<!--end of row-->*/}
                </div>
                {/*<<!--end of container-->*/}
            </div>
            {/*<!--end bar-->*/}
            <nav id="menu1" className="bar bar--sm bar-1 hidden-xs">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 col-md-2" style={{paddingTop: '5px'}}>
                            <div className="bar__module">
                                <Link className="brand-link" to="/"><span className="brand"><i
                                    className="fa fa-calendar" aria-hidden="true"></i> harmonizely</span></Link>
                            </div>
                            {/*<!--end module-->*/}
                        </div>
                        {/*{%  block menu_top %}*/}
                        <div className="col-lg-10 col-md-10 text-right text-left-xs text-left-sm">
                            <div className="bar__module">
                                <ul className="menu-horizontal text-left">
                                    <li>
                                        <Link to="/why-us/">Why Harmonizely?</Link>
                                    </li>
                                    <li>
                                        <Link to="/features/">Features</Link>
                                    </li>
                                    <li>
                                        <Link to="/for-teams/">For Teams</Link>
                                    </li>
                                    <li>
                                        <Link to="/pricing/">Pricing</Link>
                                    </li>
                                    <li>
                                        <Link to="/about/">About</Link>
                                    </li>
                                    <li>
                                        <a href={process.env.BLOG_URL}>Blog</a>
                                    </li>
                                </ul>
                            </div>
                            {/*<!--end module-->*/}
                            <div className="bar__module">
                                <a className="btn btn--sm btn-outline" href={process.env.LOGIN_URL}>
                                    <span className="btn__text">
                                        Log in
                                    </span>
                                </a>
                                <a className="btn btn--primary btn--black" href={process.env.REGISTER_URL}>
                                    <span className="btn__text">
                                        Sign up free
                                    </span>
                                </a>
                            </div>
                            {/*<!--end module-->*/}
                        </div>
                        {/*{% endblock %}*/}

                    </div>
                    {/*<!--end of row-->*/}
                </div>
                {/*<!--end of container-->*/}
            </nav>
            {/*<!--end bar-->*/}
        </div>
        <div className="main-container">
            {children}
            <Footer />
        </div>
        <CookieConsent
            debug={process.env.IS_PROD === 'false'}
            location="bottom"
            containerClasses="cookie-consent"
            contentStyle={{ color: "#181818", margin: "0px 15px", flex: "unset", display: "block" }}
            buttonStyle={{ background: "none", color: "#fff", margin: 0 }}
            buttonClasses="cookie-consent-btn"
            buttonText="Accept"
            buttonId="cookie-consent-btn">
            By continuing to use this site you consent to the use of cookies in accordance with{" "}
            <a target="_blank" style={{color: "#181818"}} href={process.env.PRIVACY_URL}>our cookie policy.</a>
        </CookieConsent>
        </>
    );
};

export default Layout;
